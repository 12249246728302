import React from "react"
import { navigate } from "gatsby"
import { Flex } from "@chakra-ui/core"
import { StringParam, useQueryParam } from "use-query-params"

import PageContainer from "../../components/PageContainer"
import AuthContainer from "../../components/Account/AuthContainer"

export default function Login() {
  const [returnUrl] = useQueryParam("return_url", StringParam)
  const [checkoutUrl] = useQueryParam("checkout_url", StringParam);

  const handleAuthSuccess = () => {
    if (returnUrl) {
      navigate(returnUrl)
    } else if (checkoutUrl) {
      navigate(checkoutUrl)
    } else {
      navigate('/account/addresses')
    }
  }

  return (
    <PageContainer>
      <Flex align="center" justify="center">
        <AuthContainer
          width="23.75rem"
          onLoginSuccess={handleAuthSuccess}
          onSignUpSuccess={handleAuthSuccess}
        />
      </Flex>
    </PageContainer>
  )
}
